import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./018.png";
import Page18_b from "./Page18_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page18() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
任何事都是從一個決心、
<br /> 一粒種子開始。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Rènhé shì dōu shì cóng yīgè juéxīn，
<br />yīlìzhǒng zǐ kāishǐ.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
النوايا بذور الأعمال والإرادة والعزيمة سبيل تحقيقها.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
Her şey bir kararla başlar. Niyetler，eylemlerin tohumlarıdır ve azim onları gerçekleştirme yoludur.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/UXXr64RwTCQ"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/pFGXkMQzYM8"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
على قدر النوايا تكون العطايا
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
مفاتيح الخير: النوايا الطيبةُ مفتاح الخير في هذه الحياة، وبوابةُ التوفيق والرزق، وهي من العبادات الخفية، فكلما كان داخل الإنسان نقياً ابتسمت له الدروب، ولانت له الخطوب، ومالت إليه القلوب.
وجود النية يعزز الإرادة: النية محلها القلب؛ فالقلب يصلح بصلاح النية، ويفسد بفسادها.
الفرق بين الإرادة والعزيمة: تُعدّ الإرادة والعزيمة من أهم الصفات التي تميّز الإنسان الناجح والمتميّز. فهما القوة الدافعة التي تمكّنه من تجاوز التحديات والصعاب، وتحقيق الأهداف والطموحات.
الإرادة هي الرغبة القوية والصادقة في تحقيق شيء ما، بينما العزيمة هي الصبر والمثابرة والاستمرار في السعي نحو الهدف، مهما كانت الصعوبات والعقبات.
</p>
 <h5 className="turkish-text mt-4">
Niyetleriniz ne ölçüdeyse karşılığı da o ölçüde olacaktır.
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
好的意念，帶來好的結果。
</h5>
<p className="pinyin-text">
Hǎo de yìniàn, dài lái hǎo de jiéguǒ
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page18_b />
</section>
);
}
export default Page18;




import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./041.png";
import Page41_b from "./Page41_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page41() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
孝順就是讓父母安心。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Xiàoshùn jiùshì ràng fùmǔ ānxīn.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
برُّ الوالدين يعني عدم إثارة قلقِهما.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
Ebeveynlere itaat etmek，onların gönlünü rahatlatmak demektir.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/L9NqcmXmfaU"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/g4QQVEoGZdg"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
رِضَا اَللَّهِ فِي رِضَا اَلْوَالِدَيْنِ, وَسَخَطُ اَللَّهِ فِي سَخَطِ اَلْوَالِدَيْنِ.“ _ حديث شريف"
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
لم يتوقف النبي صلى الله عليه وسلم عن متابعة الوصايا لأصحابه رضي الله عنهم؛ حتى يوصلهم إلى رضا الله عز وجل ويبعدهم عن سخطه وعقابه؛ وليفوزوا بالدنيا والآخرة.
وفي هذا الحديث بعض وصاياه العظيمة، حيث يبين أن إرضاء الوالدين سبيل لرضا الله عز وجل؛ فيعفو ويغفر له، وذلك بالإحسان إليهما، والقيام بخدمتهما، وترك عقوقهما، حتى يرضيا عن ابنهما.
وأما إذا أساء المرء إلى والديه بالقول أو الفعل، فقد باء بسخط الله عز وجل.
</p>
 <h5 className="turkish-text mt-4">
“Allah'ın rızası, anne ve babanın rızasındadır; Allah'ın öfkesi, anne ve babanın öfkesindedir.“ _ Hadis-i Şerif
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
安拉的滿足在於父母的滿足，安拉的不悅在於父母的不悅
(聖訓)
</h5>
<p className="pinyin-text">
Ān lā de mǎnzú zàiyú fùmǔ de mǎnzú 
ān lā de bù yuè zàiyú fùmǔ de bù yuè
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page41_b />
</section>
);
}
export default Page41;




import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./014.png";
import Page14_b from "./Page14_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page14() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
說好話如口吐蓮花，
<br />說壞話如口吐毒蛇。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Shuō hǎo huà rú kǒu tǔ liánhuā， 

<br />shuō huàihuà rú kǒu tǔ dúshé.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
الكلمات الرقيقة كالزهور النقية 
والكلمات الجارحة كالثعابين السامة.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
Güzel sözler，ağzından nilüfer çiçekleri çıkarmak gibidir; 
Kırıcı sözler ise，ağzından zehirli yılanlar çıkarmak gibidir.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/gdbsLDUG0Hk"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/GfNI-Pu8tLs"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
جراحات السِّنان لها التآم ولا يلتامُ ما جرح اللسان. _ مثل عربي
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
كلمة تأثير عجيب في النفوس، فكم من كلمة تشجيع دفعت الكثيرين قدماً إلى الأمام، وكم من كلمة توبيخ وتثبيط كتمت إبداعات الكثيرين، فاختر كلماتك بعناية، فرب كلمة أطلقتها دون انتباه أبكت من سمع، فالكلمة الجارحة لا تنسى، وجرحها لا يشفى، وتبقى عالقة في الذاكرة لا تبرحها، فاجعل لسانك وسيلة ثواب لا سوط عقاب.
</p>
 <h5 className="turkish-text mt-4">
Mızrak yaraları iyileşir, fakat dilin açtığı yaralar iyileşmez. _ Arap atasözü
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
箭傷可以治療，舌頭造成的傷害是永遠的。
(阿拉伯諺語)
</h5>
<p className="pinyin-text">
Jiàn shāng kěyǐ zhìliáo, shétou zàochéng de shānghài shì yǒngyuǎn de
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page14_b />
</section>
);
}
export default Page14;




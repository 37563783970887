import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./044.png";
import Page44_b from "./Page44_b";
import Tilt from "react-parallax-tilt";
import AOS from "aos";
function Page44() {
  useEffect(() => {
    AOS.init({ duration: 1200, once: false });
  }, []);
  return (
    <section>
      <Container fluid className="home-about-section" id="about">
        <Container>
          <Row className="justify-content-center">
            <Col
              md={6}
              className="home-about-description d-flex flex-column align-items-center"
              data-aos="fade-right"
            >
              <p
                id="home-section"
                className="home-about-body text-center chinese-text"
              >
                世間有兩件事不能等，
                <br />
                一是「行孝」，二是「行善」。
                <div
                  className="pinyin-text"
                  data-aos="fade-down"
                  data-aos-delay="300"
                >
                  Shìjiān yǒu liǎng jiàn shì bùnéng děng，
                  <br />
                  yī shì `xíngxiào'，èr shì `xíngshàn'.
                </div>
                <div
                  className="arabic-text"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  عملان لا يؤجلان بر الوالدين، وفعل الخير.
                </div>
                <div
                  className="pinyin-text"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  Bu dünyada iki şey ertelenemez: Biri anne ve babaya saygı
                  göstermek，diğeri ise iyilik yapmak.
                </div>
              </p>
              <Row className="video-container justify-content-center">
                <Col md={4} sm={10} className="mb-3" data-aos="fade-right">
                  <iframe
                    width="180"
                    height="150"
                    src="https://www.youtube.com/embed/A9Uv41kt4nY"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ borderRadius: "10px" }}
                  ></iframe>
                </Col>
                <Col md={4} sm={10} className="mb-3" data-aos="fade-right">
                  <iframe
                    width="180"
                    height="150"
                    src="https://www.youtube.com/embed/t9pj-aaaHnY"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ borderRadius: "10px" }}
                  ></iframe>
                </Col>
                <Col md={4} sm={10} className="mb-3" data-aos="fade-right">
                  <iframe
                    width="180"
                    height="150"
                    src="https://www.youtube.com/embed/ApN8dydkxWE"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ borderRadius: "10px" }}
                  ></iframe>
                </Col>
              </Row>
            </Col>
            <Col md={6} className="myAvtar" data-aos="fade-left">
              <Tilt>
                <img src={myImg} className="img-fluid" alt="avatar" />
              </Tilt>
            </Col>
          </Row>
          <Row className="justify-content-center mt-4">
            <Col md={10} className="text-center" data-aos="fade-up">
              <h4 className="arabic-text">
                “خيرُ ما يخلِّفُ الرَّجُلُ من بعدِه ثلاثٌ: ولدٌ صالِحٌ يدعو لَه،
                وصَدَقةٌ تجري يبلغُهُ أجرُها، وعِلمٌ يُعمَلُ بِه من بعدِهِ.” -
                حديث شريف
                <span style={{ fontWeight: 300 }}> </span>
              </h4>
              <p>
                من رحمة الله سبحانه وفضله أن جعل أسبابا كثيرة لرفع الدرجات
                وغفران الذنوب ودوام الثواب حتى بعد الممات. وفي هذا الحديث يبين
                النبي صلى الله عليه وسلم أنه إذا مات الإنسان انقطع عنه عمله في
                الدنيا، فلا زيادة في العمل؛ فإن كان محسنا فله إحسانه، وإن كان
                مسيئا فلا مجال للعمل ولا للتوبة بعد الموت؛ فلا يصل إليه أجر
                وثواب من شيء من عمله، إلا إن ترك ثلاثة أشياء؛ فإن ثوابها
                وفائدتها لا تنقطع عنه، أولها: الصدقة الجارية: وهي التي يجري
                نفعها فيدوم أجرها مثل الوقف وغيره، ثانيها: علم ينتفع به بعد
                موته، ثالثها: ولد صالح مؤمن يدعو له.
              </p>
              <h5 className="turkish-text mt-4">
                “Bir adamın ardından bırakabileceği en hayırlı üç şey şunlardır:
                Onun için dua eden salih bir evlat, devam eden bir sadaka ki
                onun sevabı ona ulaşır ve arkasından insanların faydalandığı bir
                ilim.“ _ Hadis-i Şerif
              </h5>
              <p style={{ fontWeight: 300 }}></p>
              <h5 className="chinese-text mt-4">
                一個人在他身後留下的最好的東西是三樣:
                一個會為他祈禱的正義的孩子，一個會得到回報持續的慈善事業，
                以及在他之後會有用的知識。（聖訓）
              </h5>
              <p className="pinyin-text">
                Yīgèrén zài tā shēnhòu liú xià de zuì hǎo de dōngxī shì sān
                yàng: Yīgè huì wèi tā qídǎo de zhèngyì de háizi, yīgè huì dédào
                huíbào chíxù de císhàn shìyè, . Yǐjí zài tā zhīhòu huì yǒuyòng
                de zhīshì
              </p>
              <p
                className="chinese-reference"
                style={{ fontWeight: 300 }}
                data-aos="fade-up"
                data-aos-delay="500"
              ></p>
            </Col>
          </Row>
        </Container>
      </Container>
      <Page44_b />
    </section>
  );
}
export default Page44;

import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./06.png";
import Page6_b from "./Page6_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page6() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
自己吃飯才會飽，
<br />自己做的功夫才牢靠。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Zìjǐ chīfàn cái huì bǎo，
<br />zìjǐ zuò de gōngfū cái láokào.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
لن تشبع إلا إذا أكلت بنفسك 
ولن تكون ماهرا إلا إذا عملت بيدك.

</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
Kendi yemeğini yemezsen doymazsın，kendin çalışmazsan usta olamazsın.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/yX5AbAsNV98"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/xUxWmoKk63Y"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
“ما أكلَ أحدٌ طعامًا قطُّ ، خيرًا من أنْ يأكلَ من عمَلِ يدِهِ“ _ حديث شريف“
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
يوصينا النبي صلى الله عليه وسلم على السعي للعمل، ليعف الإنسان نفسه وأهله وعياله ويلبي احتياجاتهم، فأفضل مكاسب الإنسان ما كان من يده وثمرة جهده، وكذلك كان أنبياء الله صلوات الله وسلامه عليهم يأكلون من عمل أيديهم، من صناعة أو زراعة أو رعي للأغنام أو غير ذلك من الأعمال.
</p>
 <h5 className="turkish-text mt-4">
“İnsanoğlu, kendi emeğiyle kazandığı yemekten daha hayırlı bir yemek yememiştir.“ _ Hadis-i Şerif 
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
沒有食物比自己親手做的更好吃。(聖訓)
</h5>
<p className="pinyin-text">
Méiyǒu shíwù bǐ zìjǐ qīnshǒu zuò de gèng hào chī
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page6_b />
</section>
);
}
export default Page6;




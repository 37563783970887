import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./036.png";
import Page36_b from "./Page36_b";
import Tilt from "react-parallax-tilt";
import AOS from "aos";
function Page36() {
  useEffect(() => {
    AOS.init({ duration: 1200, once: false });
  }, []);
  return (
    <section>
      <Container fluid className="home-about-section" id="about">
        <Container>
          <Row className="justify-content-center">
            <Col
              md={6}
              className="home-about-description d-flex flex-column align-items-center"
              data-aos="fade-right"
            >
              <p
                id="home-section"
                className="home-about-body text-center chinese-text"
              >
                多原諒人一次，就多造一次福。
                <br /> 把量放大，福就大。
                <div
                  className="pinyin-text"
                  data-aos="fade-down"
                  data-aos-delay="300"
                >
                  Duō yuánliàng rén yīcì， jiù duō zào yīcì fú.
                  <br />
                  Bǎ liàng fàngdà，fú jiù dà.
                </div>
                <div
                  className="arabic-text"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  كلما سامحت كلما زدت رفعة وبركة.
                </div>
                <div
                  className="pinyin-text"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  Bir kişiyi bir kez daha affettiğinizde，yeniden iyilik yapmış
                  olursunuz. Hoşgörünüz arttıkça，iyiliğiniz de artar.
                </div>
              </p>
              <Row className="video-container justify-content-center">
                <Col md={6} sm={10} className="mb-3" data-aos="fade-right">
                  <iframe
                    width="250"
                    height="170"
                    src="https://www.youtube.com/embed/eZM38kVeeB8"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ borderRadius: "10px" }}
                  ></iframe>
                </Col>
                <Col md={6} sm={10} className="mb-3" data-aos="fade-right">
                  <iframe
                    width="250"
                    height="170"
                    src="https://www.youtube.com/embed/sAZRYagW1VI"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ borderRadius: "10px" }}
                  ></iframe>
                </Col>
              </Row>
            </Col>
            <Col md={5} className="myAvtar" data-aos="fade-left">
              <Tilt>
                <img src={myImg} className="img-fluid" alt="avatar" />
              </Tilt>
            </Col>
          </Row>
          <Row className="justify-content-center mt-4">
            <Col md={10} className="text-center" data-aos="fade-up">
              <h4 className="arabic-text">
                “وَالكَاظِمِينَ الغَيْظَ وَالعَافِينَ عَنِ النَّاسِ وَاللهُ
                يُحِبُّ المُحْسِنِينَ“ - آية كريمة - (آل عمران: 134)
                <span style={{ fontWeight: 300 }}> </span>
              </h4>
              <p>
                إنّ الكظم لغةً هو الإمساك، والجَمعُ للشَّيء، وأيضاً يُعرف بأنّه
                حبس الشيء عند امتلائه، أما الغيظ فيعني في اللغة الغَضَب، وقيل
                أيضاً: (الغَيْظ غَضَبٌ كامنٌ للعاجز، وقيل: هو أشدُّ من الغَضَب)،
                ومعنى كَظْم الغَيْظ اصطلاحاً: هو السكوت عن الغضب؛ وكظم غيظاً أي
                (سكت عليه، ولم يُظْهِره بقولٍ أو فعلٍ، مع قُدْرته على إيقاعه
                بعدوِّه). التسامح والعفو: عند المقدرة من مبادئ الأخلاق الكريمة
                التي يدعو إليها الإسلام، بل هي أعظمها شأناً لأن العفو من شيم
                الكرام. شيم الأنبياء: العفو والتسامح من شيم الأنبياء فهذا يوسف
                عليه السلام عفا عن إخوته الذين رموه في البئر وأبعدوه عن أبيه
                &#123;قَال لَا تَثْرِيبَ عَلَيْكُمُ الْيَوْمَ يَغْفِرُ اللَّهُ
                لَكُمْ وَهُوَ أَرْحَمُ الرَّاحِمِينَ&#125; [يوسف: 92] مرتبة
                الإحسان: رفع الله مرتبة المتسامح ومن يعفو عن الناس إلى مرتبة
                المحسنين لما للتسامح من دور كبير في زيادة الألفة والمحبة بين
                الناس وفي تعميم السلام والمودة بين المجتمعات والشعوب.
              </p>
              <h5 className="turkish-text mt-4">
                “ Allah, öfkesini kontrol edenleri, insanları affedenleri ve
                ihsan edenleri sever.“
              </h5>
              <p style={{ fontWeight: 300 }}></p>
              <h5 className="chinese-text mt-4">
                那些能咽下怒氣，又能恕人的人，
                安拉是喜愛行善者的。(古蘭經第三章134節)
              </h5>
              <p className="pinyin-text">
                Nàxiē néng yàn xià nùqì, yòu néng shù rén de rén, ān lā shì
                xǐ’ài xíngshàn zhě de. (Gǔlánjīng dì sān zhāng 134 jié)
              </p>
              <p
                className="chinese-reference"
                style={{ fontWeight: 300 }}
                data-aos="fade-up"
                data-aos-delay="500"
              ></p>
            </Col>
          </Row>
        </Container>
      </Container>
      <Page36_b />
    </section>
  );
}
export default Page36;

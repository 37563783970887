import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./025.png";
import Page25_b from "./Page25_b";
import Tilt from "react-parallax-tilt";
import AOS from "aos";
function Page25() {
  useEffect(() => {
    AOS.init({ duration: 1200, once: false });
  }, []);
  return (
    <section>
      <Container fluid className="home-about-section" id="about">
        <Container>
          <Row className="justify-content-center">
            <Col
              md={6}
              className="home-about-description d-flex flex-column align-items-center"
              data-aos="fade-right"
            >
              <p
                id="home-section"
                className="home-about-body text-center chinese-text"
              >
                人要克服難，不要被難克服。
                <div
                  className="pinyin-text"
                  data-aos="fade-down"
                  data-aos-delay="300"
                >
                  Rén yào kèfú nán，bùyào bèi nàn kèfú.
                </div>
                <div
                  className="arabic-text"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  يجب أن نتغلب على الصعوبات وألا تتغلب علينا.
                </div>
                <div
                  className="pinyin-text"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  Zorlukların üstesinden gelmeyi bilmeli ve zorluklara
                  yenilmemeliyiz.
                </div>
              </p>
              <Row className="video-container justify-content-center">
                <Col md={6} sm={10} className="mb-3" data-aos="fade-right">
                  <iframe
                    width="250"
                    height="170"
                    src="https://www.youtube.com/embed/Gc8nxNIy9gk"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ borderRadius: "10px" }}
                  ></iframe>
                </Col>
                <Col md={6} sm={10} className="mb-3" data-aos="fade-right">
                  <iframe
                    width="250"
                    height="170"
                    src="https://www.youtube.com/embed/O6UrhFhfVgI"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ borderRadius: "10px" }}
                  ></iframe>
                </Col>
              </Row>
            </Col>
            <Col md={5} className="myAvtar" data-aos="fade-left">
              <Tilt>
                <img src={myImg} className="img-fluid" alt="avatar"  style={{ marginTop: '-100px' }}  />
              </Tilt>
            </Col>
          </Row>
          <Row className="justify-content-center mt-4">
            <Col md={10} className="text-center" data-aos="fade-up">
              <h4 className="arabic-text">
                ومن يتهيَّب صــعودَ الجبالِ .. يعــــشْ أبدَ الدهرِ بينَ الحفر
                <span style={{ fontWeight: 300 }}> </span>
              </h4>
              <p>
                في طريق كل مجد مجتهد صعوبات وعقبات جمّة قد تبدو مخيفة مرعبة،
                وكأنها تحذرنا من المضي قدما، فمن خافها واستسلم ورضي من الغنيمة
                بالإياب وقف في مكانه، وخسر طموحاته، وتبخّرت أحلامه، ومن اقتحمها
                وركب المخاطر دون خوف حقّق ما يريد ووصل إلى ما يصبو إليه، فالنجاح
                يعشق الجرأة والمغامرة، وقد فاز باللذة الجسور.
              </p>
              <h5 className="turkish-text mt-4">
                Dağlara tırmanmayı göze alamayanlar, tüm ömürlerini çukurlarda
                geçirir
              </h5>
              <p style={{ fontWeight: 300 }}></p>
              <h5 className="chinese-text mt-4">
                不敢爬山的人，將永遠活在深坑裡 (阿拉伯諺語)
              </h5>
              <p className="pinyin-text">
                Bù gǎn páshān de rén, jiāng yǒngyuǎn huó zài shēn kēng lǐ
              </p>
              <p
                className="chinese-reference"
                style={{ fontWeight: 300 }}
                data-aos="fade-up"
                data-aos-delay="500"
              ></p>
            </Col>
          </Row>
        </Container>
      </Container>
      <Page25_b />
    </section>
  );
}
export default Page25;

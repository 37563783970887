import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./022.png";
import Page22_b from "./Page22_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page22() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
小事不做， 大事難成。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Xiǎoshì bù zuò， dàshì nán chéng.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
لا يمكننا تحقيق أشياء عظيمة إذا رفضنا القيام بالأشياء الصغيرة التي تبدو عادية.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
Küçük işler yapmazsanız büyük işlerde başarılı olamazsınız.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/FeMJGScrrsk"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/RrL1OUlHJ2g"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
لا تَحقِرَنَّ مِنَ المَعْرُوف شَيْئًا، وَلَو أنْ تَلقَى أخَاكَ بوجهٍ طليقٍ.“ _ حديث شريف“
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
لا تستصغرن من المعروف وعمل الخير ولو كان صغيراً بأن تلقى أخاك ووجهك إليه منبسط، فمن حسن خلق الرجل أن يحدث صاحبه وهو مقبل عليه بوجهه.
</p>
 <h5 className="turkish-text mt-4">
“Hiçbir iyiliği küçümseme, bu birini Güleryüz'le karşılamak olsa bile.“ _ Hadis-i Şerif
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
不要輕視任何善意的行為，即使是見到人們時臉上帶
著愉悅的表情（聖訓）
</h5>
<p className="pinyin-text">
Bùyào qīngshì rènhé shànyì de xíngwéi, jíshǐ shì jiàn dào rénmen shí liǎn shàng dài zhe yúyuè de biǎoqíng
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page22_b />
</section>
);
}
export default Page22;




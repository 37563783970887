import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./08.png";
import Page8_b from "./Page8_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page8() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
雙手健全卻不肯做事的人，
<br />等於是沒有手的人。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Shuāng shǒu jiànquán què bù kěn zuòshì de rén， 

</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
اليدان القويتان اللتان لا تعملان مَثَلها كشخص ليس لديه يدان.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
Sağlam elleri varken çalışmamak，eli olmayan kişiyle aynıdır.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/pBurgGKSId0"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/S1L4SGVV-Mc"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
“إني لأرى الرجل فيعجبني، فأقول“ 
أله حرفة؟ فإن قالوا: لا، سقط من عيني.“ _ عمر بن الخطاب
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
كان الأنبياء والصلحاء يعيشون مِن كَسْب أيديهم وكانت لهم حرف يعملون بها، فداود عليه السلام كان حداداً، وزكريا عليه السلام كان نجاراً، واشتغل نبينا صلى الله عليه وسلم قبل النبوة بالتجارة.
وكان سيدنا عمر بن الخطاب رضي الله عنه تعجبه عبادة الرجل وقوته وفتوته، لكن عندما يعلم أنه لم يتعلم حرفة يكتسب منها يفقد مكانته عنده. فعلى المؤمن أن يتخذ له صنعة يكتسب بها وينفق منها.
</p>
 <h5 className="turkish-text mt-4">
“Bir insan beğenildiğinde eğer bir zanaatı yoksa gözden düşer.“ _ Hz. Ömer
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
我看到一個我有興趣的人
我會說:他有手藝嗎?
如果他們說:不 他就會從我的視線中消失。
(哈里發 奧馬爾所說）
</h5>
<p className="pinyin-text">
Wǒ kàn dào yīgè wǒ yǒu xìngqù de rén wǒ huì shuō: Tā yǒu shǒuyì ma? Rúguǒ tāmen shuō: Bù tā jiù huì cóng wǒ de shìxiàn zhōng xiāoshī
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page8_b />
</section>
);
}
export default Page8;




import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./035.png";
import Page35_b from "./Page35_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page35() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
發脾氣是短暫的發瘋，會醜化了自己。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Fā píqì shì duǎnzàn de fāfēng， huì chǒuhuàle zìjǐ.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
الغضب يشبه موجة من الجنون المؤقت ويجعلنا نبدو قبيحين.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
Öfke，geçici bir delilik gibidir ve bizi çirkinleştirir.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/LbQl33K17yc"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/y7nn4ScpB3A"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
أول الغضب جنون وآخره ندم. _ مثل عربي
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
عكس هذا المَثل حقيقة مؤلمة حول طبيعة الغضب وتأثيره على القرارات والسلوكيات البشرية:
المعنى الكلي: عندما يغضب الشخص، يكون في حالة من عدم التوازن العاطفي والعقلي، مما يدفعه إلى اتخاذ قرارات غير حكيمة أو القيام بأفعال قد تكون ضارة له أو للآخرين، ويُصوَّر الغضب هنا كنوع من “الجنون“ لأنه يؤثر بشكل كبير على التفكير العقلاني، ثم بعد انقضاء لحظة الغضب، يبدأ الشخص في إدراك العواقب السلبية لأفعاله ويدرك أنه تصرف بطريقة غير مدروسة، مما يؤدي إلى الندم.
العبرة: ينبغي الحذر من العواقب السلبية للغضب غير المنضبط، والحرص على التعقّل والتروي في مواجهة المشاعر القوية، هي دعوة للتأمل في السلوكيات والقرارات تحت وطأة العواطف، وتذكر أن الحكمة والتروي يكفلان تجنب العديد من المشكلات الناتجة عن الغضب.
</p>
 <h5 className="turkish-text mt-4">
Öfkenin başlangıcı çılgınlık, sonu pişmanlıktır. _ Arap atasözü
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
憤怒的開始是發瘋，它的結束是悔恨
(阿拉伯諺語)
</h5>
<p className="pinyin-text">
Fènnù de kāishǐ shì fāfēng tā de jiéshù shì huǐhèn
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page35_b />
</section>
);
}
export default Page35;




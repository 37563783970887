import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./033.png";
import Page33_b from "./Page33_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page33() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
每個人都能縮小自己，
<br />大家的空間就變大了。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Měi gèrén dōu néng suōxiǎo zìjǐ，
<br /> dàjiā de kōngjiān jiù biàn dàle.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
إذا قلل كل شخص من غروره، فسيكون هناك مساحةٌ كبيرةٌ للجميع.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
Herkes biraz alçakgönüllü olursa，herkes için daha fazla alan olur.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/0N1QGVw_Uo4"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/iDNucdHncjk"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
تواضعُ المرءِ يكرمه. _ مثل عربي
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
عبارة “تواضعُ المرءِ يكرمه“ تحمل معنىً عميقًا يتعلق بصفة التواضع وكيفية تأثيرها الإيجابي على الفرد والمجتمع:
المعنى..
- تعريف التواضع: هو عدم التعالي أو التكبر على أحد، ومراعاة الآخرين وعدم الاستعلاء عليهم. هو سمة تُظهر مدى احترام الشخص لنفسه وللآخرين.
- كرامة الشخص: الكرامة هنا تشير إلى الاحترام والتقدير الذي يناله الشخص من الآخرين عندما يكون الشخص متواضعًا، يُنظر إليه بنظرة إيجابية، مما يزيد من تقدير الناس له.
الخلاصة..
تعكس العبارة أهمية التواضع كصفة أساسية تعزّز من مكانة الشخص في أعين الآخرين، كما تحثّ هذه العبارة على ضرورة التحلي بالتواضع في التعاملات اليومية، مما يساهم في بناء علاقات إنسانية صحيّة ويرفع من قيمة الشخص في المجتمع، لذا، يعدّ التواضع من الصفات المحمودة التي يجب أن يسعى الجميع لتحقيقها في حياتهم.
</p>
 <h5 className="turkish-text mt-4">
Kişinin alçakgönüllülüğü onu yüceltir. _ Arap atasözü 
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
一個人的謙遜使他受到尊重。
(阿拉伯諺語)
</h5>
<p className="pinyin-text">
Yīgèrén de qiānxùn shǐ tā shòudào zūnzhòng
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page33_b />
</section>
);
}
export default Page33;




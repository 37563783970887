import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./021.png";
import Page21_b from "./Page21_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page21() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
人有兩耳兩眼、一張嘴兩隻手
<br />人要多聽多看，少說話多做事。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Rén yǒu liǎng ěr liǎng yǎn， yī zhāngzuǐ liǎng zhī shǒu， 
<br />rén yào duō tīng duō kàn， shǎo shuōhuà duō zuòshì.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
لدينا أذنان وعينان اثنتان ويدان اثنتان وفمٌ واحد 
لأننا يجب أن نلاحظ أكثر ونستمع أكثر ونفعل أكثر ونتحدث أقل.

</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
İki kulağımız，iki gözümüz，iki elimiz ve bir ağzımız olduğu için daha fazla gözlem yapmalı， daha fazla dinlemeli，daha fazla çalışmalı ve daha az konuşmalıyız.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/dqnfhBn_OTk"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/2WDD9JqUkk4"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
المرء بأفعاله لا بأقواله. _ مثل عربي
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
المعنى الكلي: توضّح هذه العبارة أن الأفعال تُعتبر المعيار الحقيقي لتقييم الأشخاص، حيث يمكن أن تتناقض الأقوال مع الأفعال، فنحن نظهر حقيقتنا من خلال سلوكنا، بدلاً من مجرد ادعاءاتنا أو ظواهر أقولنا.
العبرة: تُشجع هذه العبارة الأفراد على التفكير بعناية في تصرفاتهم، حيث أن الأفعال تعكس شخصية الفرد وصدق نيته، كما تدعو إلى أهمية الالتزام بالعمل والجهد بدلاً من الاكتفاء بالحديث، مما يعزز القيم الحقيقية ويحقق الأثر الإيجابي في الحياة وفي العلاقات مع الآخرين.
</p>
 <h5 className="turkish-text mt-4">
Kişi, söyledikleriyle değil, yaptıklarıyla ölçülür. _  Arap atasözü
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
要看一個人他的行為而不只是他的言語。
（阿拉伯諺語）
</h5>
<p className="pinyin-text">
Yào kàn yīgèrén tā de xíngwéi ér bù zhǐshì tā de yányǔ
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page21_b />
</section>
);
}
export default Page21;




import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./028.png";
import Page28_b from "./Page28_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page28() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
有心就有福，有願就有力。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Yǒuxīn jiù yǒufú，yǒu yuàn jiù yǒulì.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
نستمد القوة من الطموح، ونرى النّعم بقلوبنا.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
Niyet edersen，güç bulursun. Gönülden istersen，istediklerini bolca bulursun.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/0df-HbqZPP4"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/a53Y1RLE31Q"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
على قدر أهل العزم تأتي العزائم. _ مثل عربي
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
لمعنى الكلي: يعبّر هذا الشطر من البيت الشعري عن العلاقة المتبادلة بين الإرادة الفردية والنجاح في تحقيق الأهداف، إذ يشير إلى أن الذين يمتلكون عزيمة قوية وقابلية للتحدي هم من يمكنهم أن يواجهوا الصعوبات ويحققوا إنجازات كبيرة.
المغزى: المغزى من هذه العبارة هو أهمية التحلي بالعزم والإرادة في الحياة، فكلما كانت لديك قوة إرادة وتصميم على النجاح، كلما زادت فرصة تحقيق ما تسعى إليه.
العبرة: العزيمة والإرادة القوية هما المفتاح لتحقيق الإنجازات، ويجب على الأفراد تعزيز هذه الصفات في أنفسهم لمواجهة التحديات التي قد تعترض طريقهم نحو النجاح.
</p>
 <h5 className="turkish-text mt-4">
Yüce hedefler büyük çabalar gerektirir. _ Arap atasözü
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
決心來自於堅定的意志。
(阿拉伯諺語)
</h5>
<p className="pinyin-text">
Juéxīn láizì yú jiāndìng de yìzhì
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page28_b />
</section>
);
}
export default Page28;




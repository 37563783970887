import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./052.png";
import Page52_b from "./Page52_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page52() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
愛護身邊物 ，惜福且知足。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Àihù shēnbiān wù，xīfú qiě zhīzú.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
يجب أن نكون راضين وممتنين بما لدينا وأن نهتم ونحافظ على كل من حولَنا.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
Etrafımızdaki şeylere değer vermeli，sahip olduklarımız için minnettar ve tatminkâr olmalıyız.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/Fe43vsxGexg"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/jtMxkd5G8uI"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
كفى بالقناعةِ مُلكاً وبحسن الخُلق نعيماً. _ مثل عربي
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
لا حدود لرغبات النفس، فإن أطلقت لها العنان أتعبتك، فلا بد من لجمها والسيطرة عليها، ولا يكون ذلك إلا بالقناعة والرضا بما يكون، فالقنوع إنسان سويّ نفسيا، لا يغريه ما بأيدي غيره، يكتفي بما لديه، يرى كل النعم بما منحه الله، فالقناعة تاج لا يقدر قيمته إلا ذو الخلق الحسن الذي تزينت نفسه بمحاسن الأخلاق، وهما من أعظم النعم التي لا غنى للمرء عنها.
</p>
 <h5 className="turkish-text mt-4">
Kanaatkarlıkla krallığa, iyi ahlakla da mutluluğa erişilir. _ Arap atasözü
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
知足品最高，善行最大福。
(阿拉伯諺語)
</h5>
<p className="pinyin-text">
Zhīzú pǐn zuìgāo, shànxíng zuìdà fú
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page52_b />
</section>
);
}
export default Page52;




import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./037.png";
import Page37_b from "./Page37_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page37() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
對別人不要計較，
<br />對自己要好好檢討。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Duì biérén bùyào jìjiào，
<br />duì zìjǐ yào hǎohǎo jiǎntǎo.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
كن لطيفاً ومتسامحاً مع نفسك ومع الآخرين.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
Kendine ve başkalarına karşı nazik ve hoşgörülü ol.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/ok-6PmdGuYQ"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/nrYFg8m07fI"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
أعقلُ النَّاسِ أعذرُهم للنَّاس. _ مثل عربي
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
لمعنى الكلي:
تشير العبارة إلى أن الأشخاص الأذكياء أو الحكماء لديهم القدرة على فهم الظروف والأسباب التي قد تدفع الآخرين إلى التصرف بطريقة معينة، سواء كانت إيجابية أو سلبية، فعندما يعذر الشخص الآخرين، فإنه يتجاوز الانطباعات السطحية للأفعال ويفهم الدوافع والإكراهات التي قد تواجههم، وهذا يعكس عمق الفهم وقدرة على التعاطف، كما تشجع على عدم إصدار أحكام سريعة على الآخرين بناءً على تصرفات أو كلمات قد تكون ناتجة عن ضغوط أو حالات معينة.
الخلاصة:
إجمالًا، تعبّر العبارة عن قيمة العقل والتفهم في التعامل مع الآخرين، فالأشخاص الأكثر حكمة هم الذين يعرفون كيف يقدّرون الظروف التي يعيشها الآخرون ويتقبّلونها، مما يُسهم في خلق بيئة من التفاهم والقبول.
</p>
 <h5 className="turkish-text mt-4">
En akıllı kişi, başkalarına en çok hoşgörü gösterendir. _ Arap atasözü
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
最有智慧的人是最能原諒人的人
（阿拉伯諺語）
</h5>
<p className="pinyin-text">
Zuì yǒu zhìhuì de rén shì zuì néng yuánliàng rén de rén
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page37_b />
</section>
);
}
export default Page37;




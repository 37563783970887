import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/home.png";
import Home2 from "./Home2";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Home() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 1200, once: false });

    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth <= 768); 
    };

    window.addEventListener('resize', checkScreenSize);
 
    checkScreenSize();

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);
 
 
 
 

  return (
    <section
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        flexDirection: isSmallScreen ? "column" : "row", 
      }}
    >
      <Container fluid className="home-about-section" id="about">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col
              md={7}
              className="home-about-description d-flex flex-column align-items-center"
              data-aos="fade-right"
            >
              <p id="home-section" className="home-about-body1 text-center">
                靜思語
                <br />
                一束智慧之花-
                <div
                  className="pinyin-text"
                  data-aos="fade-down"
                  data-aos-delay="300"
                >
                  通往文學、倫理和人類幸福的途徑
                </div>
              </p>
            </Col>
            <Col
              md={4}
              className="myAvtarhome"
              data-aos="fade-left"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: isSmallScreen ? "20px" : "0", 
              }}
            >
              <Tilt>
                <img src={myImg} className="img-fluid" alt="avatar" />
              </Tilt>
            </Col>
          </Row>
        </Container>
      </Container>

      <Home2 />
    </section>
  );
}

export default Home;

import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./047.png";
import Page47_b from "./Page47_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page47() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
原諒別人是美德，原諒自己是損德。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Yuánliàng biérén shì měidé，yuánliàng zìjǐ shì sǔn dé.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
مسامحة الآخرين فضيلة، أما مسامحة النفس فهي عكس ذلك.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
Başkalarını affetmek bir erdemdir，kendini affetmek ise bir zayıflıktır.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/2Hboc0sakCI"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/TBUNB8v3Lg0"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
فَلَا تُزَكُّوا أَنفُسَكُمْ هُوَ أَعْلَمُ بِمَنِ اتَّقَىٰ.“ _ آية كريمة"
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
ينهى الله عز وجل عباده أن يمدحوا أنفسهم، وأن يبرّئوها عن الآثام، وأن يثنوا عليها بإعجاب أو رياء، وأن يدّعوا الطهارة عن المعاصي، بل عليهم أن يحمدوا الله على الطاعة، ويحذروا المعصية، فالله هو العليم بمن اتقى المعاصي.
</p>
 <h5 className="turkish-text mt-4">
“Kendinizi temize çıkarmayın; kimin daha takva sahibi olduğunu ‘O' daha iyi bilir.“ _ Ayet-i Kerime
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
你們不要自命純潔，祂至知誰是虔敬的人。
(古蘭經53章32節)
</h5>
<p className="pinyin-text">
Nǐmen bùyào zìmìng chúnjié, tā zhì zhī shuí shì qiánjìng de rén
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page47_b />
</section>
);
}
export default Page47;




import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./026.png";
import Page26_b from "./Page26_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page26() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
成功是優點的發揮，
<br />失敗是缺點的累積。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Chénggōng shì yōudiǎn de fāhuī，
<br />shībài shì quēdiǎn de lěijī.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
النجاح هو إظهار مواطنِ القوةِ والفشل هو تراكم نِقاط الضعف.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
Başarı，güçlü yönlerin ortaya çıkmasıdır; başarısızlık ise zayıf yönlerin birikimidir.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/x-hRjGtSqUY"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/u86T8a7w808"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
ركِّز على إيجابياتك تنجح وتجاوز نقاط ضعفك تُفلح.
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
المعنى الكلي: تدعو هذه العبارة الأفراد إلى إيجاد توازن بين الاعتراف بنقاط قوتهم والعمل على تحسين نقاط ضعفهم، من خلال التركيز على الإيجابيات وبذل الجهد لتجاوز السلبيات، يمكن للفرد أن يحقق النجاح والفلاح في مجالات حياته المختلفة.
عبرة: تشجع العبارة الأشخاص على أن يكونوا إيجابيين ونشطين في سعيهم نحو التحسين الذاتي، وأن الإيجابية والتصميم على التغلب على العوائق هما مفتاحا النجاح الشخصي والمهني.
</p>
 <h5 className="turkish-text mt-4">
Zayıf yönlerini aşarak ilerler, pozitif yönlerine odaklanarak ise başarılı olursun
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
專注你的優點，並克服你的弱點，你就會成功。
（阿拉伯諺語）
</h5>
<p className="pinyin-text">
Zhuānzhù nǐ de yōudiǎn, bìng kèfú nǐ de ruòdiǎn
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page26_b />
</section>
);
}
export default Page26;




import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./039.png";
import Page39_b from "./Page39_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page39() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
孝順父母的孩子最有福。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Xiàoshùn fùmǔ de háizi zuì yǒufú.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
أكثر شخص موفق في حياته هو الشخص البار بوالديه.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
Hayatta en şanslı ve başarılı çocuk，anne babasına itaat edendir.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/0pViADcAyDQ"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/Co0Fkqs-aZM"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
بِرُّوا آباءكم تَبَرُّكُمْ أبناؤكم.“ _ حديث شريف"
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
يأمر النبي صلى الله عليه وسلم بالإحسان إلى الوالدين ومعاملتهم معاملة حسنة وطاعتهم والاعتناء بهم والحديث معهم بلطف، لأن ذلك سيؤدي إلى معاملة الأبناء لآبائهم بنفس الطريقة، فإذا برّ الإنسان والديه وأحسن إليهم سيجد نفس المعاملة من أبنائه، فالجزاء من جنس العمل.
</p>
 <h5 className="turkish-text mt-4">
“Babalarınıza iyilikte bulunun ki çocuklarınız da size iyilik yapsın.“ Hadis-i Şerif

</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
善待你的父母你的孩子也會善待你
（聖訓）
</h5>
<p className="pinyin-text">
Shàndài nǐ de fùmǔ nǐ de háizi yě huì shàndài nǐ
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page39_b />
</section>
);
}
export default Page39;




import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./050.png";
import Page50_b from "./Page50_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page50() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
愛心最美， 愛心的記憶最深。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Àixīn zuìměi， àixīn de jìyì zuìshēn.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
الحب من أجمل المشاعر وذكرياته تبقى محفورة في الوجدان.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
   "Sevgi en güzel duygudur，sevgiyle ilgili anılar en derinlerde olanlardır."
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/daNjsLRK5CM"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/PstjTFyV3KI"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
أَحِبَّ لِلنَّاسِ مَا تُحِبُّ لِنَفْسِكَ تَكُنْ مُؤْمِناً. _ حديث شريف
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
كان النبي صلى الله عليه وسلم يوصي أصحابه وصايا جامعة، نافعة، قليلة العبارات، كثيرة الفوائد والمعاني.
وفي هذا الحديث يوصينا أن نحب للناس كما نحب لأنفسنا وأهل بيتنا، بأن نتمنى لهم حصول الخير، واجتناب الشر، فهذا علامة على رقي الإيمان في القلب.
</p>
 <h5 className="turkish-text mt-4">
“Kendin için istediğini İnsanlar için de iste, böylece gerçek bir mümin olursun.“ _ Hadis-i Şerif
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
愛別人就像愛自己一樣，
你就會成為一個正信的人。 
(聖訓)
</h5>
<p className="pinyin-text">
Ài biérén jiù xiàng ài zìjǐ yīyàng nǐ jiù huì chéngwéi yīgè zhèngxìn de rén
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page50_b />
</section>
);
}
export default Page50;




import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./031.png";
import Page31_b from "./Page31_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page31() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
。 理直要氣和，得理要饒人
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Lǐ zhí yào qì hé，dé lǐ yào ráo rén.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
كن لطيفَ الكلام متسامحاً في كل أحوالك.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
Her durumda nazik ve affedici ol.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/Ef_9vUObMmk"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/CTTSOCcNG_k"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
“ما كان الرِّفْقُ في شيءٍ إلَّا زانَه ، ولا نُزِعَ من شيءٍ إلَّا شانَه.“ _ حديث شريف"
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
المعنى الكليّ: الحديث تعبير عن مفهوم هام في الأخلاق والسلوك، وإظهار لقيمة الرفق واللطف في التعامل مع الآخرين في المجالات المختلفة: العلاقات الشخصية ,العمل والتعاملات المهنية ,التربية
الخلاصة: يبيّن النبي صلى الله عليه وسلم في هذا الحديث أنّ الرفق يُعتبر من الفضائل الأساسية في الحياة، فالتحلّي بالرفق يعكس شخصية متفهّمة ومتعاطفة ويسهم في تحسين العلاقات الإنسانية، بينما غيابه يمكن أن يسبب الكثير من الأضرار والصعوبات، لذا، يُشجع الجميع على تبني الرفق في تعاملاتهم اليومية لتحقيق حياة أكثر سعادة وسلامًا.
</p>
 <h5 className="turkish-text mt-4">
“Nezaket bir şeye eklendiğinde onu güzelleştirir,
çıkarıldığında ise onu eksiltir“ - Hadis-i Şerif
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
仁慈不存在於任何事物中，除非被完善的言語美化。
仁慈不從任何事物中被奪走，
除非用醜陋的語言使他人蒙羞。(聖訓)
</h5>
<p className="pinyin-text">
Réncí bù cúnzài yú rènhé shìwù zhòng, chúfēi bèi wánshàn de yányǔ měihuà.
Réncí bù cóng rènhé shìwù zhòng bèi duó zǒu, chúfēi yòng chǒulòu de yǔyán shǐ tārén méng xiū.
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page31_b />
</section>
);
}
export default Page31;




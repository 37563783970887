import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./027.png";
import Page27_b from "./Page27_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page27() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
人多，力大，福就大。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Rén duō，lì dà，fú jiù dà.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
مع المزيد من الناس سيكون لدينا المزيد من القوة، والمزيد من البركة.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
İnsanlar birlik olursa，güç büyük olur ve kazançtaki bereket de büyük olur.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/CzKGifRcS0A"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/H6J3A98AzxM"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
لا يعجز القومُ إذا تعاونوا. _ مثل عربي
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
المعنى الكلي: تؤكد العبارة على أهمية التعاون بين الأفراد في مختلف المجالات، سواء كان ذلك في العمل، أو المجتمع، أو حتى العائلة. التعاون يعزز الفعالية ويزيد من فرص النجاح، ويمكَّن الأفراد من مواجهة التحديات معًا بشكل أفضل مما يمكنهم القيام به بشكل فردي.
فالمغزى من هذه العبارة هو أن القوة تأتي من الاتحاد، وأن التعاون يمكن أن يكون سر النجاح في أي سعي إنساني، وتحث الناس على إقامة علاقات عمل إيجابية ومساندة بعضهم البعض لتحقيق الأهداف المشتركة.
العبرة: التعاون والتكاتف يمكن أن يتغلبا على التحديات والصعوبات، ويحققان النجاحات التي قد تبدو صعبة أو مستحيلة للنفس الواحدة.
</p>
 <h5 className="turkish-text mt-4">
İnsanlar birlik olursa başarısız olmazlar. _ Arap atasözü
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
如果人們合作，就不會無助
(阿拉伯諺語)
</h5>
<p className="pinyin-text">
Rúguǒ rénmen hézuò, jiù bù huì wú zhù
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page27_b />
</section>
);
}
export default Page27;




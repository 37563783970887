import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./017.png";
import Page17_b from "./Page17_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page17() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
脾氣、嘴巴不好，心地再好，
<br />也不能算是好人。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Píqì，zuǐbā bu hǎo，xīndì zài hǎo，
<br />yě bùnéng suànshì hǎorén.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
إذا كان مزاجنا سيئاً ولساننا حاداً فلا يمكن اعتبارنا أشخاصاً جيدين حتى لو كان قلبنا طيباً.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
Kalbi ne kadar iyi olursa olsun，huysuz ve ağzı bozuk olan biri iyi bir insan sayılmaz.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/pP8Kme9cpXM"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/WXUqD9v_hQc"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
“ليس المُؤمِنُ بالطَّعَّانِ ولا اللَّعَّانِ ولا الفاحشِ ولا البَذيءِ.“ _ حديث شريف“
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
يبين النبي صلى الله عليه وسلم بعضاً من صفات المؤمن الكامل بأنه:
ليس بطعان، والطعان هو الذي يقع في أعراض الناس بالذم والغيبة ونحوهما.
ولا بلعان: واللعان هو الذي يكثر من الدعاء على الناس أو يسبهم.
ولا بفحاش: والفحاش هو الذي في كلامه وفعله فحش.
ولا بالبذيء والبذيء هو الرجل الفاحش بكلامه وإن كان الكلام صدقًا.
</p>
 <h5 className="turkish-text mt-4">
“Mümin, söz taşıyan, lanet eden, ağzı bozuk veya kaba biri olamaz“ _ Hadis-i Şerif
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
正信的人，是不誹謗，是不咒罵
是不猥褻，是不粗俗的人。(聖訓)
</h5>
<p className="pinyin-text">
Zhèngxìn de rén, shì bù fěibàng, 
shì bù zhòumà shì bù wěixiè, shì bù cūsú de rén
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page17_b />
</section>
);
}
export default Page17;




import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./032.png";
import Page32_b from "./Page32_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page32() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
學點頭，學低頭，不要學拳頭。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Xué diǎntóu，xué dītóu，bùyào xué quántóu.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
تقبل الآخرين وكن مرناً ولا تجادل ولا تخاصم.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
Başkalarını kabul etmeyi，esnek olmayı öğren; tartışma ve kavga etme.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/dUbLOhtIZl0"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/bzr1cljSV1s"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
إذا كنت في كل الأمور معاتباً  ..  صديقَك لم تلق الذي لا تعاتبه
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
لا قدرة لك على العيش وحيدا، فأنت بحاجة إلى أصدقاء أوفياء يشاركونك أفراحك ويخففون عنك أحزانك وأتراحك، وللمحافظة على هؤلاء لا بد من التغاضي عن أخطائهم، والتغافل عن زلاتهم، فإن كنت كالصياد الماهر تتصيد الزلات وتتعقب الهفوات وتناقش فيها وتعاتب فسيهجرك أصدقاؤك ويملّك أحبابك وتبقى وحيدا غريبا ، فالإنسان الكامل الذي لا يخطئ لم يُخلق بعد.
</p>
 <h5 className="turkish-text mt-4">
Eğer her konuda arkadaşlarını sürekli eleştirirsen,
etrafında eleştirecek kimseyi bulamazsın
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
如果事事責怪他人，你就找不到一個朋友。
(阿拉伯諺語)
</h5>
<p className="pinyin-text">
Rúguǒ shì shì zéguài tārén nǐ jiù zhǎo bù dào yīgè péngyǒu
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page32_b />
</section>
);
}
export default Page32;




import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./038.png";
import Page38_b from "./Page38_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page38() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
太陽光大，父母恩大。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Tàiyáng guāngdà，fùmǔ ēn dà.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
نعمة الوالدين عظيمةٌ مثلُ نعمة الشمس.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
Güneşin ışığı ne kadar büyükse，ebeveynlerin sevgisi de o kadar büyüktür.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/1KbUfQq_3-0"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/ulhlIS7Cdis"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
الْزَمْ قَدَمَها، فَثَمَّ الْجَنَّةُ.“ _ حديث شريف"
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
عن معاوية السلمي- رضي الله عنه- قال: أتيت رسول الله صلى الله عليه وسلم فقلت: يا رسول الله إني كنت أردت الجهاد معك، أبتغي بذلك وجه الله والدار الآخرة، قال: ويحك، أحية أمك؟ قلت: نعم يا رسول الله، قال: “ويحك الزم رجلها فثم الجنة“
- الزم قدمها أو رجلها: يعني أن تلتزم بخدمة والدتك وتكون بجانبها، وتمنحها الدعم والرعاية التي تحتاجها. كلمة “قدمها“ تعكس أهمية الوقوف بجانب الأم والاهتمام بها.
فثمّ الجنة: تشير إلى أن رضا الله سبحانه وتعالى والجنة مرتبطان ببر الوالدين، وخاصة الأم، يعكس فمن خلال خدمة وإكرام الوالدين، يمكن للشخص أن يكسب رضا الله وينال الجزاء العظيم في الآخرة.
الخلاصة..
يُشجع الحديث المسلمين على تكريم والديهم ورعايتهم وخصوصاً الأمهات، ويؤكد على أن ذلك هو طريق للوصول إلى النعيم في الآخرة.
</p>
 <h5 className="turkish-text mt-4">
“Ayaklarının dibine bağlan, çünkü orası cennettir.“ _ Hadis-i Şerif
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
天堂在母親的腳下（聖訓）
</h5>
<p className="pinyin-text">
Tiāntáng zài mǔqīn de jiǎoxià
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page38_b />
</section>
);
}
export default Page38;




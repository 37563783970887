import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import 'aos/dist/aos.css';

function Home2() {
  const [age, setAge] = useState(0);

  useEffect(() => {
    const birthYear = 2015; 
    const currentYear = new Date().getFullYear();
    setAge(currentYear - birthYear);
  }, []);

  return (
    <section
      style={{
        display: "flex",          
        justifyContent: "center",   
        alignItems: "center",      
        minHeight: "100vh",       
      }}
    >
      <Container fluid className="home-about-section" id="about">
        <Container>
          <Row className="justify-content-center">
            <Col md={7} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
              <p id="home-section" className="home-about-body1 text-center">
                باقة من زهور
                <br />
                الحكمة ..
                <br />
                منهج في الأدب والأخلاق والسعادة الإنسانية
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
    </section>
  );
}

export default Home2;

import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./011.png";
import Page11_b from "./Page11_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page11() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
口說好話，心想好意，
<br />手做好事，腳走好路。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Kǒu shuō hǎo huà，xīn xiǎng hǎoyì，
<br />shǒu zuò hǎoshì，jiǎo zǒu hǎo lù.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
تكلم الكلمات الطيبة وكن ذا نوايا حســــــــــــــــنة 
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
Güzel sözler söyle，iyi niyetle salih amellerde bulun，doğru yolda olursun.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/MxAGITYanhE"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/Q5ARHBrUR9w"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
“إنَّ الله لا ينْظُرُ إِلى أجْسَامِكُمْ، ولا إِلى صُوَرِكمْ، وَلَكن ينْظُرُ إلى قُلُوبِكمْ وأعمالكم.“ _ حديث شريف“
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
يبين النبي صلى الله عليه وسلم أنّ الله سبحانه وتعالى لا ينظرُ إلى صور العباد وأجسامهم، هل هي جميلةٌ أو ذَميمةٌ؟ هل هي كبيرة أو صغيرة؟ وهل هي صحيحة أو سَقيمة؟ وكذلك لا ينظر إلى أموالهم، هل هي كثيرة أو قليلة؟ 
فالله عز وجل لا يؤاخذ عباده ولا يحاسبهم على هذه الأمور وتفاوتهم فيها، ولكن ينظر إلى قلوبهم وما فيها من التَّقوى واليقين، والصدق والإخلاص، وينظر إلى أعمالهم من حيث صلاحها وفسادها فيثيب ويجازي عليها.
</p>
 <h5 className="turkish-text mt-4">
“Allah, bedenlerinize veya şeklinize bakmaz, yalnız kalplerinize ve amellerinize bakar“ _ Hadis-i Şerif
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
真主不看你的身體，也不看你的外表
而是看你的內心和你的行為。
（聖訓）
</h5>
<p className="pinyin-text">
Zhēnzhǔ bù kàn nǐ de shēntǐ, yě bù kàn nǐ de wàibiǎo 
ér shì kàn nǐ de nèixīn hé nǐ de xíngwéi
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page11_b />
</section>
);
}
export default Page11;




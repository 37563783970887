import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./010.png";
import Page10_b from "./Page10_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page10() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
要用心，不要操心、煩心。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Yào yòngxīn，bùyào cāoxīn，fánxīn.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
افعل الأمور بتأنٍ وانتبه ولا تقلق.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
İşleri dikkatle，özen göstererek ve endişelenmeden yap.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/UcYdObD-eaw"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/5aDd-N7bMA0"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
“احْرِصْ عَلَى مَا يَنْفَعُكَ، واسْتَعِنْ بِاللَّهِ، ولَا تَعْجَزْ.“ _ حديث شريف“
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
هذه وصية من الرسول عليه الصلاة والسلام لأمته، وهي وصية جامعة مانعة بأن يحرص على ما ينفعه في أمر دينه ودنياه، ثم يطلب المعونة من الله ويتوكل عليه، ولا يعجز بل يستمر في العمل ولا يتأخر ويكسل عنه.
</p>
 <h5 className="turkish-text mt-4">
“Faydalı olduğunu düşündüğün şeylere özen göster.  Allah'a sığın, ümitsizliğe kapılıp çaresiz hissetme.“ _ Hadis-i Şerif
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
要注意那些對你有益的事情
尋求安拉的幫助就不會失敗。
(聖訓）
</h5>
<p className="pinyin-text">
Yào zhùyì nàxiē duì nǐ yǒuyì de shìqíng 
xúnqiú ān lā de bāngzhù jiù bù huì shībài
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page10_b />
</section>
);
}
export default Page10;




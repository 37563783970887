import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./01.png";
import Page1_b from "./Page1_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page1() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
愛惜生命是本分，
<br />尊重互愛是福分。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Àixī shēngmìng shì běn fēn，
<br /> zūnzhòng hù ài shì fú fen.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
الحياة تستحق اهتمامنا، وحبنا واحترامنا لبعضنا بعضاً نعمة عظيمة.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
Yaşamın kıymetini bilmek görevimizdir， karşılıklı saygı ve sevgi ise bize nasip olmuş büyük bir lütuftur.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/caYNASH8Lus"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/nzlt8DPn9lw"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
" إِنَّ المُؤْمِنَ لِلْمُؤْمِنِ كَالْبُنْيَانِ يَشُدُّ بَعْضُهُ بَعْضًا." _ حديث شريف
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
هذا تبيين من النبي ﷺ يفيد الحث على إعانة المؤمن لأخيه المؤمن ونصرته وأن ذلك أمر لابد منه، كما أن البناء لا يتم ولا تحصل فائدته ولا يتحقق الغرض منه إلا بأن يكون بعضه يمسك بعضًا، بحيث يشد بعضه بعضًا ويقويه، وإلا تنهدمُ أجزاؤه، وينفرط نظامه ويختل بنيانه.
</p>
 <h5 className="turkish-text mt-4">
“Mümin, müminle sıkı sıkıya bağlı bir bina gibidir; birbirini destekler.“
(Hadis-i Şerif)
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
« 穆民和穆民之間就應該如同一座緊密結合的建築物，互相支撐。»
</h5>
<p className="pinyin-text">
“Mù mín hé mù mín zhī jiān jiù yīnggāi rútóng yīzuò jǐnmì jiéhé de jiànzhú wù, hùxiāng zhīchēng.“ - Shèng xùn
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page1_b />
</section>
);
}
export default Page1;




import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./045.png";
import Page45_b from "./Page45_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page45() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
對父母要知恩、感恩、報恩。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Duì fùmǔ yào zhī ēn，gǎn'ēn，bào'ēn.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
يجب أن نكون ممتنين وشاكرين، وأن نرد المعروف لوالدينا.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
Ebeveynlerimize karşı minnettar ve şükran dolu olmalı，iyiliklerine karşılık vermeliyiz.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/oYuq11JlPag"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/7pXrgq3M3gQ"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
برُّ الوالدين قصةٌ تكتبها أنت ويرويها لك أبناؤك.
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
“برُّ الوالدين“: يُشير إلى معاملتهم بالإحسان والعناية، والاهتمام بمتطلباتهم، وطلب رضاهم. فهذا الأمر يعتبر واجبًا دينيًا واجتماعيًا في الإسلام.
“قصةٌ تكتبها أنت“: تعني أن الأعمال والأفعال التي تقوم بها تجاه والديك هي نقاط مهمة في حياتك، وتشكل ما يمكنك تدوينه في سجل حياتك. فكل عمل صالح تقوم به مع والديك يترك أثراً ويُسجل كجزء من قصتك الشخصية.
“ويرويها لك أبناؤك“: تعني أن الأجيال القادمة ستستفيد من تصرفاتك مع والديك، فقد يتعلم أبناؤك من سلوكياتك ومن برِّك لوالديك، وبالتالي يقومون هم أيضاً ببرِّك عندما تصبح والدًا.
الخلاصة: تحث العبارة ضرورة العناية بوالديك، وتُظهر أن برهما ليس فقط عملاً يقوم به الفرد، ولكنه استثمار في العلاقات الأسرية التي تستمر عبر الأجيال وتُروي قصص الحب والنجاح والتضحية.
</p>
 <h5 className="turkish-text mt-4">
Anne ve babaya yapılan iyilik, sizin yazdığınız ve çocuklarınızın
size anlattığı bir hikayedir
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
孝敬父母是你寫的故事，
也是你的孩子告訴你的故事
(阿拉伯諺語)
</h5>
<p className="pinyin-text">
Xiàojìng fùmǔ shì nǐ xiě de gùshì yěshì nǐ de háizi gàosù nǐ de gùshì
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page45_b />
</section>
);
}
export default Page45;




import React, { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "./048.png";
import Page48_b from "./Page48_b";
import Tilt from 'react-parallax-tilt';
import AOS from 'aos';
function Page48() {
useEffect(() => {
AOS.init({ duration: 1200, once: false });
}, []);
return (
<section>
<Container fluid className="home-about-section" id="about">
<Container>
<Row className="justify-content-center">
<Col md={6} className="home-about-description d-flex flex-column align-items-center" data-aos="fade-right">
 <p id="home-section" className="home-about-body text-center chinese-text">
以好心看人，人人都是好人；
<br />以壞心看人，人人都是壞人。
<div className="pinyin-text" data-aos="fade-down" data-aos-delay="300">
Yǐ hǎoxīn kàn rén，rén rén dōu shì hǎorén; 
<br />yǐ huài xīn kàn rén，rén rén dōu shì huàirén.
</div>
<div className="arabic-text" data-aos="fade-up" data-aos-delay="500">
صاحب النية الحسنة يرى الخير في الناس جميعاً والعكس صحيح.
</div>
<div className="pinyin-text" data-aos="fade-up" data-aos-delay="500">
İyi niyetle bakarsan，herkes iyi insandır; kötü niyetle bakarsan，herkes kötü insandır.
</div>
</p>
<Row className="video-container justify-content-center">
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/qOLHGPb1FME"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
<Col md={6} sm={10} className="mb-3" data-aos="fade-right">
 <iframe
width="250"
height="170"
src="https://www.youtube.com/embed/yU08H8TGgJ4"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
style={{ borderRadius: "10px" }}
 ></iframe>
</Col>
</Row>
 </Col>
<Col md={6} className="myAvtar" data-aos="fade-left">
<Tilt>
<img src={myImg} className="img-fluid" alt="avatar" />
 </Tilt>
 </Col>
</Row>
<Row className="justify-content-center mt-4">
<Col md={10} className="text-center" data-aos="fade-up">
 <h4 className="arabic-text">
كل إناءٍ بما فيه ينضَح. _ مثل عربي
<span style={{ fontWeight: 300 }}>   </span>
 </h4>
<p>
ظنك بالناس كظنك بنفسك، فتوقع الخير من الجميع، وتفاءل بهم، فصاحب النيّة الحسنة واثق من نفسه، يزرع الأمل أينما حلّ، يمضي نحو هدفه بثبات ويقين وهدوء وسكينة، ويرى اليسر في ساعات العسر، فتراه دائما يرجح الأمل على اليأس، والرجاء على القنوط، أمّا فاسد النية قلق مضطرب لا تهدأ له نفس ولا يرتاح له بال، يتوقع الشر ويتخيله في كل مكان وفي ثنايا كل عمل.
</p>
 <h5 className="turkish-text mt-4">
Her kap yalnızca içinde taşıdığını dökebilir. _ Arap atasözü
</h5>
<p style={{ fontWeight: 300 }}></p>
<h5 className="chinese-text mt-4">
你放進碗裡的東西就會從湯匙裡出來
（阿拉伯諺語）
</h5>
<p className="pinyin-text">
Nǐ fàng jìn wǎn lǐ de dōngxī jiù huì cóng tāngchí lǐ chūlái
 </p>
<p className="chinese-reference" style={{ fontWeight: 300 }} data-aos="fade-up" data-aos-delay="500">
  
</p>
</Col>
</Row>
</Container>
</Container>
<Page48_b />
</section>
);
}
export default Page48;



